// import { ref, provide } from 'vue';

import firebase from "firebase";
// Required for side-effects
import "firebase/firestore";

import FIREBASE_CONFIG from "./.env.firebase";
import useAuth from "./firebase-auth";
import useUtil from "./use-util";
import _ from 'lodash';

// initialize firebase, this is directly from the firebase documentation
// regarding getting started for the web
if (firebase.apps.length === 0) {
    firebase.initializeApp(FIREBASE_CONFIG);
}

export default function() {
    const { user } = useAuth();

    // const appPages = ref([]);
    // console.log('PROVIDE CALLED', appPages);
    // provide('appPages', appPages);

    const getScoreboardById = async (scoreboardId: string) => {
        try {
            const path = 'scoreboard';
            // console.log('getting my user info', path);
            const query = await firebase.firestore().collection(path).doc(scoreboardId).get();
            const scoreboard: any = query.data();

            const usersObj: any = await getScoreboardUserIds([ scoreboard ] );
            // console.log('usersObj A', usersObj);
            if (usersObj && usersObj[scoreboard.user_id] && usersObj[scoreboard.user_id].info && usersObj[scoreboard.user_id].info.handle) {
                scoreboard.username = usersObj[scoreboard.user_id].info.handle;
            }
            return scoreboard;
        } catch (error) {
            console.log('getScoreboardById error', error);
            return;
        }

        return;
    }

    async function getScoreboardUserIds(scoreboardAr: any []) {
        const userIdsObj: any = {};
        for (const scoreboard of scoreboardAr) {
            userIdsObj[scoreboard.user_id] = true;
        }

        const ar = Object.keys(userIdsObj);
        // console.log('ar A', ar);
        const query = await firebase.firestore().collection('users')
            .where('id', 'in', ar)
            .get();

        query.forEach((snap) => {
            const data = snap.data();
            // console.log('data A', data);
            userIdsObj[snap.id] = data;
        });

        return userIdsObj;
    }

    const getScoreboards = async (lim?: any, startAfter?: any) => {
        let limit = 30;
        if (lim) {
            limit = lim;
        }
        const dataAr: any [] = [];
        let lastSnapshot = undefined;
        try {
            const path = 'scoreboard';
            // console.log('getting my user info', path);
            let query = undefined;
            if (startAfter) {
                query = await firebase.firestore().collection(path)
                    .orderBy("datetime", "desc").limit(limit)
                    .startAfter(startAfter)
                    .get();
            } else {
                query = await firebase.firestore().collection(path)
                    .orderBy("datetime", "desc").limit(limit)
                    .get();
            }


            lastSnapshot = query.docs[query.docs.length-1];

            query.forEach((snap) => {
                const data = snap.data();
                data.id = snap.id;
                dataAr.push(data);
            });

            const usersObj: any = await getScoreboardUserIds(dataAr);
            // console.log('usersObj B', usersObj);
            for (const scoreboard of dataAr) {
                if (usersObj && usersObj[scoreboard.user_id] && usersObj[scoreboard.user_id].info && usersObj[scoreboard.user_id].info.handle) {
                    scoreboard.username = usersObj[scoreboard.user_id].info.handle;
                }
            }


        } catch (error) {
            console.log('getScoreboards error', error);
        }

        return {data: dataAr, last: lastSnapshot};
    };


    const getAmountUsedInAccrualPeriod = async (companyId: string, email: string, start: any, end: any) => {


        if (typeof start === 'string') {
            start = parseInt(start.split('-')[0] + start.split('-')[1] + start.split('-')[2]);
        }

        if (typeof end === 'string') {
            end = parseInt(end.split('-')[0] + end.split('-')[1] + end.split('-')[2]);
        }

        try {
            const path = 'company/'+companyId+'/timeSheetRecords';
            // console.log('getAmountUsedInAccrualPeriod querying path', path);
            // console.log('getAmountUsedInAccrualPeriod with email('+email+') start('+start+') end('+end+')');
            const query = await firebase.firestore().collection(path)
                .where('email', '==', email)
                .where("dateQuery", ">=", start)
                .where("dateQuery", "<=", end)
                .where("project", "==", "Leave")
                .get();


            const dataAr: any [] = [];
            let totalHours = 0;
            query.forEach((snap) => {
                const data = snap.data();
                data.id = snap.id;
                dataAr.push(data);
                if (data && data.project && data.hours) {
                    if (data.project === "Leave") {
                        totalHours += data.hours;
                    }
                }
            });
            // console.log('getAmountUsedInAccrualPeriod dataAr', dataAr);
            // console.log('getAmountUsedInAccrualPeriod totalHours', totalHours);
            return totalHours;
        } catch (error) {
            console.log('getAmountUsedInAccrualPeriod error', error);
            return 0;
        }

    };


    return {
        getScoreboards,
        getScoreboardById,
        getAmountUsedInAccrualPeriod,
    };
}
