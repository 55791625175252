<template>
  <ion-page>
    <ion-header :translucent="true" class="ion-no-border" >
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title style="cursor: pointer" @click="reloadIfScores()">{{ folder }}</ion-title>
        <ion-item lines="none" slot="end" v-if="user && user.photoURL" @click="showUserPopup" >
          <ion-avatar >
            <img :src="user.photoURL">
          </ion-avatar>
        </ion-item>
      </ion-toolbar>
<!--      <TimeViewContainerHeader v-if="user && folder === 'Time'" :inUser="user"  :companyId="stateCompanyId" @update-employee-timesheets="updateTimesheetsFromEvent($event)" @download-timesheet-records-csv="downloadCsv($event)"/>-->
    </ion-header>

    <ion-content >
      <ScoresContainer v-if="folder === 'Scores' && !gamecenterScoreboardId" @scoreboard-emit-from-scoreboard="scoreboardEmitFromScoreboard($event)" :scoreboards="scoreboards"/>
      <Gamecenter v-if="gamecenterScoreboardId" :scoreboard="gamecenterScoreboard"/>
<!--      <PtoContainer v-if="user && folder === 'PTO'" :inUser="user" :companyId="stateCompanyId"/>-->

      <ion-infinite-scroll
          @ionInfinite="loadData($event)"
          threshold="100px"
          id="infinite-scroll"

      >
        <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonAvatar, IonItem, modalController, IonInfiniteScroll, IonInfiniteScrollContent  } from '@ionic/vue';
import  UserPopup  from '../views/UserPopup.vue';
import {useRoute, useRouter} from 'vue-router';
import { ref, computed, watch, provide } from 'vue';
import useFirebaseAuth from "../hooks/firebase-auth";
import ScoresContainer from '../components/ScoresContainer.vue';
import Gamecenter from '../components/Gamecenter.vue';
import useScoreProvider from "@/hooks/firebase-score-provider";
import useScoreRecordUtil from "@/hooks/score-record-util";
import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";
export default {
  name: 'ViewContainer',
  components: { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonAvatar, IonItem, ScoresContainer, Gamecenter, IonInfiniteScroll, IonInfiniteScrollContent },
  setup() {
    const router = useRouter();
    const {  user } = useFirebaseAuth();
    const { getScoreboardById, getScoreboards } = useScoreProvider();
    const { getEarliestScorebardId } = useScoreRecordUtil();
    const route = useRoute();
    const username = ref('');
    const password = ref('');
    const gamecenterScoreboardId = ref('');
    const gamecenterScoreboard = ref(undefined);
    const scoreboards: any = ref([]);
    const lastScoreboard: any = ref(undefined);

    getScoreboards().then((sbs) => {
      console.log('scoreboards', sbs.data);
      scoreboards.value = sbs.data;
      lastScoreboard.value = sbs.last;
      console.log('lastScoreboard', lastScoreboard);
    });

    const folder = ref(route.name);
    const matchedFolder = computed(() => route.name);
    console.log('route', folder.value);
    watch(matchedFolder, () => {
      const tmp: any = route.name;
      folder.value = tmp;
      console.log('route change', tmp);
    });

    function scoreboardEmitFromScoreboard(ev: any) {
      console.log('ViewContainer.vue scoreboardEmitFromScoreboard', ev);
      gamecenterScoreboard.value = ev;
    }
    function loadData(ev: any) {

      if(!gamecenterScoreboardId.value && folder.value === 'Scores'){
        console.log('loadData', ev);
        setTimeout(() => {

          console.log('Loaded data');
          if (ev && ev.target) {
            ev.target.complete();
          }
        }, 10000);

        getScoreboards(undefined, lastScoreboard.value).then((sbs) => {
          console.log('scoreboards infinite', sbs.data);
          for (const sbSing of sbs.data) {
            scoreboards.value.push(sbSing);
          }
          // scoreboards.value = sbs.data;
          lastScoreboard.value = sbs.last;
          console.log('lastScoreboard infinite', lastScoreboard);

          if (ev && ev.target) {
            ev.target.complete();
          }
        });


      } else if (gamecenterScoreboardId.value) {
        console.log('gamecenter immediate complete', ev);
        ev.target.complete();
      } else {
        console.log('case other immediate complete', ev);
        ev.target.complete();
      }
    }

    const query = ref(route.query);
    const matchedQuery = computed(() => route.query);
    console.log('query', query.value);
    if (query.value && query.value.scoreboardId) {
      gamecenterScoreboardId.value = query.value.scoreboardId.toString();
      getScoreboardById(gamecenterScoreboardId.value).then((sb) => {
        console.log('gamecenterScoreboardId.value', gamecenterScoreboardId.value);
        console.log('sb', sb);
        if (sb) {
          console.log('sb', sb);
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          //@ts-ignore
          gamecenterScoreboard.value = sb;
        }
      });
    }
    watch(matchedQuery, () => {
      const tmp: any = route.query;
      query.value = tmp;
      gamecenterScoreboardId.value = tmp.scoreboardId;
      console.log('query change', tmp);
    });

    async function reloadIfScores() {
      if (folder.value && folder.value.toString().toLowerCase() === CONFIG_ROUTE_SCORES) {
        await router.replace( { path: '/' + CONFIG_ROUTE_SCORES } );
      }
    }

    const showUserPopup = async () => {
      const modal = await modalController
          .create({
            component: UserPopup,
            cssClass: 'userModalCss',
          })
      return modal.present();
    };

    return {
      showUserPopup,
      folder,
      username,
      password,
      user,
      gamecenterScoreboardId,
      gamecenterScoreboard,
      scoreboards,
      scoreboardEmitFromScoreboard,
      loadData,
      reloadIfScores
    }
  }
}
</script>

<style>

</style>
