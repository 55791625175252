<template>
  <ion-grid v-if="!queryText">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" v-for="scoreboard in scoreboards" :key="scoreboard.id">
        <Scoreboard :scoreboard="scoreboard" :scoreboardId="scoreboard.id" @scoreboard-emit-from-scoreboard="scoreboardEmitFromScoreboard($event)"/>
      </ion-col>
    </ion-row>
  </ion-grid>

<!--  <ion-infinite-scroll
      @ionInfinite="loadData($event)"
      threshold="100px"
      id="infinite-scroll"

  >
    <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>-->
</template>

<script lang="ts">

import { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip} from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import useScoreProvider from "@/hooks/firebase-score-provider";
import Scoreboard from "../components/Scoreboard.vue";

export default defineComponent({
  name: 'ScoresContainer',
  components: { IonCol, IonRow, IonGrid, Scoreboard, /*IonInfiniteScroll, IonInfiniteScrollContent*/ },
  props: { inUser: Object, companyId: String, resultObjects: Object, recordsToDownloadCsv: Object, scoreboards: Object },
  setup(props, { emit } ) {
    const queryText: any = ref('');


    /*const scoreboards: any = ref([]);
    const { getScoreboards } = useScoreProvider();
    console.log('getScoreboards() called');
    getScoreboards().then((sbs) => {
      // console.log('scoreboards', sbs);
      scoreboards.value = sbs;
    });*/


    function scoreboardEmitFromScoreboard(ev: any) {
      console.log('ScoresContainer.vue scoreboardEmitFromScoreboard', ev);
      emit('scoreboard-emit-from-scoreboard', ev);
    }

    return {
      // scoreboards,
      queryText,
      scoreboardEmitFromScoreboard,

    }
  }
});
</script>
