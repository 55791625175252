

import useUtil from '@/hooks/use-util'
import {
    SPORT_STAT_FG, SPORT_STAT_FG_ABBREV,
    SPORT_STAT_ORIGINAL_GROUNDBALL,
    SPORT_STAT_ORIGINAL_GROUNDBALL_ABBREV
} from "@/hooks/config-service";
export default function() {
    const { getStorageFileUrlPre } = useUtil();

    function getExpandedScoreRecord(sr: any, scoreboard?: any, teamA?: any, teamB?: any) {
        const byText = ' by ';
        const scoreRecord = JSON.parse(JSON.stringify( sr ));
        let team: any = {};
        let players: any;
        // console.log('scoreRecord A', scoreRecord);
        if (scoreboard && scoreboard.event) {
            if (scoreboard.event.teamAId === scoreRecord.teamId) {
                team = teamA;
            } else if (scoreboard.event.teamBId === scoreRecord.teamId) {
                team = teamB;
            }
        }

        if (team) {
            if (team.players) {
                players = team.players;
            }
            if (team.name) {
                scoreRecord.teamName = team.name;
            }
        }

        if (scoreRecord.scorerName) {
            scoreRecord.playerName = scoreRecord.scorerName;
        } else {
            if (players) {
                if (players[scoreRecord.playerId]) {
                    if (players[scoreRecord.playerId].name) {
                        scoreRecord.playerName = players[scoreRecord.playerId].name;
                    } else if (players[scoreRecord.playerId].jersey) {
                        scoreRecord.playerName = players[scoreRecord.playerId].jersey;
                    }
                }
            }
        }

        if (!scoreRecord.assistedByName && scoreRecord.assistPlayerId) {
            if (players) {
                if (players[scoreRecord.assistPlayerId]) {
                    if (players[scoreRecord.assistPlayerId].name) {
                        scoreRecord.assistedByName = players[scoreRecord.assistPlayerId].name;
                    } else if (players[scoreRecord.assistPlayerId].jersey) {
                        scoreRecord.assistedByName = players[scoreRecord.assistPlayerId].jersey;
                    }
                }
            }
        }

        scoreRecord.url = getStorageFileUrlPre() + team.teamThumbKey;
        scoreRecord.descr1 = capitalizeStat(scoreRecord.stat) + byText + scoreRecord.playerName;
        if (scoreRecord.assistedByName) {
            scoreRecord.descr2 = 'Assisted' + byText + scoreRecord.assistedByName;
        }

        // console.log('scoreRecord B', scoreRecord);
        // console.log('scoreboard', scoreboard);
        // console.log('team', team);
        // console.log('players', players);


        return scoreRecord;
    }

    function getStatsSingleLine(statsObj: any) {
        let retStr = '';
        for (const statName in statsObj) {
            // eslint-disable-next-line no-prototype-builtins
            if (statsObj.hasOwnProperty(statName)) {
                const count = statsObj[statName];
                retStr = retStr + getStatAndCountDisplay(count, statName) + ', ';
            }
        }
        return retStr.trim().slice(0, -1).trim();
    }

    function getStatAndCountDisplay(count: number, stat: string) {
        if (count === 1 && stat.endsWith('s')) {
            return count + ' ' + stat.substring(0, stat.length - 1);
        }
        return count + ' ' + stat;
    }

    function titleCase(str: string) {
        const splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    function sortScoreRecordsDescByPeriodAndTime(scoreRecords: any[]) {
        // this.myConsoleLog('CALLED sortScoreRecordsDesc A',scoreRecords);
        if (!scoreRecords) {
            return [];
        }
        // this.myConsoleLog('CALLED sortScoreRecordsDesc B',JSON.stringify(scoreRecords,null,4));
        const tmpTimeObj: any = {};
        const tmpZulu = new Date().toISOString();

        if (scoreRecords) {
            for (const scoreRecord of scoreRecords) {
                let origOt = '';

                if (!scoreRecord.period) {
                    scoreRecord.period = '';
                }

                if (scoreRecord.period.toLowerCase().indexOf('nan') > -1) {
                    scoreRecord.period = '';
                }

                if (scoreRecord.period.toLowerCase().indexOf('ot') > -1) {
                    origOt = scoreRecord.period;
                    let tmpPStr = scoreRecord.period.replace(/ot/ig, '').trim();
                    const num = parseInt(tmpPStr) || 0;
                    tmpPStr = (num + 14).toString();
                    scoreRecord.period = tmpPStr;
                }

                if (scoreRecord.period && scoreRecord.time && !isNaN( parseInt( removeOrdinal(scoreRecord.period) ) ) ) {
                    const period = removeOrdinal(scoreRecord.period);

                    let periodNum = 23;
                    let minuteNum = 59;
                    let secondNum = 59;

                    if (scoreRecord.time) {
                        const minuteStr = scoreRecord.time.split(':')[0];
                        const secondStr = scoreRecord.time.split(':')[1];

                        periodNum = parseInt(period);
                        minuteNum = parseInt(minuteStr);
                        secondNum = parseInt(secondStr);
                    }
// this.myConsoleLog('ZMP_ZULU', tmpZulu);
// this.myConsoleLog('periodNum', periodNum);
// this.myConsoleLog('minuteNum', minuteNum);
// this.myConsoleLog('secondNum', secondNum);
                    const myDate = new Date(tmpZulu);
                    myDate.setHours(periodNum);
                    myDate.setMinutes(minuteNum);
                    myDate.setSeconds(secondNum);


                    const myDateIso = myDate.toISOString();

                    if (!tmpTimeObj[periodNum]) {
                        tmpTimeObj[periodNum] = {};
                    }
                    if (!tmpTimeObj[periodNum][myDateIso]) {
                        tmpTimeObj[periodNum][myDateIso] = [];
                    }

                    if (origOt) {
                        scoreRecord.period = origOt;
                    }
                    tmpTimeObj[periodNum][myDateIso].push(scoreRecord);

                } else {

                    // if (!tmpTimeObj.hasOwnProperty(0)) {
                    if (!tmpTimeObj['0']) {
                        tmpTimeObj[0] = {};
                    }
                    if (!tmpTimeObj[0][tmpZulu]) {
                        tmpTimeObj[0][tmpZulu] = [];
                    }

                    if (origOt) {
                        scoreRecord.period = origOt;
                    }
                    tmpTimeObj[0][tmpZulu].push(scoreRecord);

                }
            }
        }


        let periodAr: number[] = [];
        for (const period in tmpTimeObj) {
            if (typeof period === 'string') {
                periodAr.push(parseInt(period));
            } else if (typeof period === 'number') {
                periodAr.push(period);
            }
        }
        periodAr = sortArDesc(periodAr);

        // this.myConsoleLog('periodAr D',periodAr);
        // init the return ar
        const retAr: any[] = [];
        // The reason we are in the outer for loop below is
        // because we have to segment the sorting of sores by period
        // first we sort the 1st period ascending, add it to return ar, then
        // second and so on
        for (let i = 0; i < periodAr.length; i++) {
            // init the iso time ar
            const tmpTimeAr: string[] = [];

            const period: number = periodAr[i];
            for (const tmpTimeIso in tmpTimeObj[period]) {
                // this.myConsoleLog('period E',period);
                // this.myConsoleLog('tmpTimeIso E',tmpTimeIso);
                tmpTimeAr.push(tmpTimeIso);
            }

            // Sort the timestamps so we know how to sort
            // the score records...same way
            const timeAr = sortDateArAsc(tmpTimeAr);

            // Now loop through the time array in order
            // because our score records will come out the same order
            timeAr.forEach((timeSingle: string) => {
                // this.myConsoleLog('timeSingle F',timeSingle);
                const scoreRecordAr: any = tmpTimeObj[period][timeSingle];
                scoreRecordAr.forEach( (scoreRecord: any) => {
                    if (scoreRecord.period === '99th') {
                        scoreRecord.period = 'Unk';
                        delete scoreRecord.period;
                    }
                    if (scoreRecord.time === '59:59') {
                        delete scoreRecord.time;
                    }
                    // this.myConsoleLog(JSON.stringify(scoreRecord.info.period));
                    retAr.push(scoreRecord);
                });
            });
        }

        // this.myConsoleLog('CALLED sortScoreRecordsDesc C retAr',JSON.stringify(retAr,null,4));
        if (scoreRecords.length !== retAr.length) {
            return scoreRecords;
        } else {
            return retAr;
        }
    }

    function sortDateAsc(a: string, b: string) {
        return new Date(b) < new Date(a) ? 1 : -1;
    }

    function sortDateArAsc(ar: any) {
        if (Array.isArray(ar)) {
            return ar.sort(sortDateAsc);
        } else {
            return [];
        }
    }

    function sortArDesc(ar: number[]) {
        if (Array.isArray(ar)) {
            return ar.sort(sortNumberDesc);
        } else {
            return [];
        }
    }

    function sortNumberDesc(a: number, b: number) {
        return b - a;
    }

    /*function sortNumber(a: number, b: number) {
        return a - b;
    }*/

    function removeOrdinal(str: any) {
        // this.myConsoleLog('removeordinal typeof '+typeof str)
        if (str && typeof str === 'string') {

            if (str.toLowerCase().indexOf('ot') > -1) {

                const abc = str.toLowerCase();

                const def = abc.replace('ot', '').trim();

                return def;
            } else {

                return str.replace(/(\d+)(st|nd|rd|th)/, '$1');
            }
        }
        return str;
    }

    function capitalizeStat(s: string) {
        if (!s) {
            return '';
        }

        s = s.replace('_', ' ');

        s = titleCase(s);

        if (s.toLowerCase() === SPORT_STAT_ORIGINAL_GROUNDBALL.toLowerCase()) {
            return SPORT_STAT_ORIGINAL_GROUNDBALL_ABBREV;
        }

        if (s.toLowerCase() === SPORT_STAT_FG.toLowerCase()) {
            return SPORT_STAT_FG_ABBREV;
        }

        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    function getEarliestScorebardId(scoreboards: any[]) {
        if (scoreboards && scoreboards.length > 0) {
            /*const sb2 = scoreboards.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.

                // @ts-ignore
                return new Date(b.datetime) - new Date(a.datetime);
            });
            console.log('sorted', sb2)*/
            return scoreboards[scoreboards.length - 1].id;
        } else {
            return;
        }

    }

    return {
        getExpandedScoreRecord,
        capitalizeStat,
        getStatsSingleLine,
        sortScoreRecordsDescByPeriodAndTime,
        getEarliestScorebardId,
    };
}
