

import { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip} from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import useScoreProvider from "@/hooks/firebase-score-provider";
import Scoreboard from "../components/Scoreboard.vue";

export default defineComponent({
  name: 'ScoresContainer',
  components: { IonCol, IonRow, IonGrid, Scoreboard, /*IonInfiniteScroll, IonInfiniteScrollContent*/ },
  props: { inUser: Object, companyId: String, resultObjects: Object, recordsToDownloadCsv: Object, scoreboards: Object },
  setup(props, { emit } ) {
    const queryText: any = ref('');


    /*const scoreboards: any = ref([]);
    const { getScoreboards } = useScoreProvider();
    console.log('getScoreboards() called');
    getScoreboards().then((sbs) => {
      // console.log('scoreboards', sbs);
      scoreboards.value = sbs;
    });*/


    function scoreboardEmitFromScoreboard(ev: any) {
      console.log('ScoresContainer.vue scoreboardEmitFromScoreboard', ev);
      emit('scoreboard-emit-from-scoreboard', ev);
    }

    return {
      // scoreboards,
      queryText,
      scoreboardEmitFromScoreboard,

    }
  }
});
