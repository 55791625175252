

// import { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip } from '@ionic/vue';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonAvatar,
  IonNote,
  IonButton,
  IonIcon,
  IonChip,
  IonSegment,
  IonSegmentButton,
} from '@ionic/vue';
import {computed, defineComponent, ref, watch} from 'vue';
import useUtil from '@/hooks/use-util'
import useScoreRecordUtil from '@/hooks/score-record-util'

export default defineComponent({
  name: 'Gamecenter',
  // components: { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip },
  components: { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonLabel,IonItem,IonAvatar, IonNote, IonButton, IonIcon, IonSegment, IonSegmentButton, IonItemGroup, },
  props: { scoreboard: Object },
  setup(props, context) {
    const { getIsDarkMode, getDisplayDateScoreboardBaseFromIsoStrShort } = useUtil();
    const { getExpandedScoreRecord, getStatsSingleLine, sortScoreRecordsDescByPeriodAndTime } = useScoreRecordUtil();
    // console.log('props.scoreboard', props.scoreboard);
    const showFooter = ref(false);
    const propsScoreboard = ref(props.scoreboard);
    const showWhich = ref('all');
    const teamA = ref({});
    const teamB = ref({});
    const scoreRecords = ref([]);
    const box = ref({});
    const hasScoreRecords = ref(false);
    const hasBox = ref(false);
    const segVal = ref('all');
    if (props.scoreboard && props.scoreboard.cache) {
      if (propsScoreboard.value) {
        teamA.value = propsScoreboard.value.cache.teamA;
        teamB.value = propsScoreboard.value.cache.teamB;
        if (propsScoreboard.value.cache.score_records) {
          // scoreRecords.value = propsScoreboard.value.cache.score_records;
        }
      }
    }
    // console.log('GamecenterVue.vue A', props.scoreboard);

    function getTextFillColor() {
      if (getIsDarkMode()) {
        return 'white';
      } else {
        return 'black';
      }
    }

    function clearDefaults() {
      teamA.value = {};
      teamB.value = {};
      scoreRecords.value = [];
    }

    function segmentChanged(ev: CustomEvent) {
      // console.log('Segment changed', ev);
      showWhich.value = ev.detail.value;
      console.log('Segment showWhich.value', showWhich.value);
    }

    watch(() => props.scoreboard, () => {
      clearDefaults();
      // console.log('GamecenterVue.vue B-1', props.scoreboard);
      if (props.scoreboard) {
        // console.log('GamecenterVue.vue B-2', props.scoreboard);
        teamA.value = props.scoreboard.cache.teamA;
        teamB.value = props.scoreboard.cache.teamB;
        if (props.scoreboard.cache.score_records) {
          // console.log('GamecenterVue.vue B-3', props.scoreboard);
          scoreRecords.value = [];
          let tmp: any = [];
          // console.log('GamecenterVue.vue B-4', props.scoreboard.cache.score_records);
          for (const srId in props.scoreboard.cache.score_records) {
            const sr = getExpandedScoreRecord(props.scoreboard.cache.score_records[srId], props.scoreboard, teamA.value, teamB.value );
            tmp.push(sr);
            // console.log('GamecenterVue.vue B-5', sr);
          }

          tmp = sortScoreRecordsDescByPeriodAndTime(tmp);
          scoreRecords.value = tmp;
          if (tmp.length > 0) {
            hasScoreRecords.value = true;
          }
          // console.log('GamecenterVue.vue B-6 scoreRecords.value', scoreRecords.value);
        }

        if (props.scoreboard.cache.prebuilt_box_expanded) {
          box.value = props.scoreboard.cache.prebuilt_box_expanded;
          hasBox.value = true;
        } else if (props.scoreboard.cache.prebuilt_box) {
          box.value = props.scoreboard.cache.prebuilt_box;
          hasBox.value = true;
        }
        // console.log('GamecenterVue.vue B', props.scoreboard);
      }

      if (!hasScoreRecords.value && hasBox.value) {
        console.log('changing segment tobox')
        showWhich.value = 'box';
        segVal.value = 'box';
      }
    });


    return {
      getDisplayDateScoreboardBaseFromIsoStrShort,
      showFooter,
      teamA,
      teamB,
      scoreRecords,
      box,
      getIsDarkMode,
      getTextFillColor,
      segmentChanged,
      getStatsSingleLine,
      showWhich,
      hasScoreRecords,
      hasBox,
      segVal,
    }
  }
});
