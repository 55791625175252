<template>
  <ion-card lines="none" style="max-width: 1200px;">

    <ion-card-header>
      <ion-row>
        <ion-col>
          <ion-card-subtitle v-if="scoreboard">{{ scoreboard.period }}</ion-card-subtitle>
          <ion-card-subtitle v-if="scoreboard && scoreboard.event">{{ scoreboard.event.gender }} {{ scoreboard.event.sport }}</ion-card-subtitle>
          <ion-card-subtitle v-if="scoreboard && scoreboard.event">{{ getDisplayDateScoreboardBaseFromIsoStrShort(scoreboard.event.datetime) }}</ion-card-subtitle>
          <!--        <ion-card-title>Card Title</ion-card-title>-->
        </ion-col>

      </ion-row>
    </ion-card-header>

    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-avatar slot="start" v-if="teamA && teamA.teamThumbKey">
                <img :src="'https://storage.googleapis.com/rt-webapp.appspot.com/'+teamA.teamThumbKey">
              </ion-avatar>

              <ion-avatar  v-if="teamA && !teamA.teamThumbKey" style="text-align: center;" slot="start">
                <svg style="vertical-align: bottom;background-color:transparent;width:36px;height:36px;" width="36" height="36" pointer-events="none">

                  <circle/>
                  <!--                  <circle *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" cx="18" cy="18" r="18" [attr.fill]="utilAvatar.getGravatarColorByLetter( utilAvatar.getTeamSubstrFromScoreboard(scoreboard, 1) )"/>-->

                  <text text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" :fill="getTextFillColor()"
                        style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                    A
                  </text>
                  <!--                  <text *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" fill="white"
                                          style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                                      B
                                    </text>-->
                </svg>
              </ion-avatar>

              <ion-row  style="width:100%;">
                <ion-col size="12"><ion-label><p v-if="teamA">{{ teamA.name }}</p></ion-label></ion-col>
<!--                <ion-col size="3" v-if="scoreboard">{{ scoreboard.scores[0].final }}</ion-col>-->
              </ion-row>
            </ion-item>
          </ion-col>

        </ion-row>



        <div v-if="scoreboard">
          <ion-row>
            <ion-col  v-for="(item, index) in scoreboard.scores[0].ar" :key="index" class="ion-text-center"><ion-label color="medium"><p style="font-weight: bold;">{{ index + 1 }}</p></ion-label></ion-col>
            <ion-col class="ion-text-center"><ion-label color="medium"><p style="font-weight: bold;">F</p></ion-label></ion-col>
          </ion-row>
          <ion-row v-for="score in scoreboard.scores" :key="score">
            <ion-col class="ion-text-center"  v-for="record in score.ar" :key="record"><ion-label ><p>{{record}}</p></ion-label></ion-col>
            <ion-col class="ion-text-center"><ion-label color="medium"><p>{{ score.final }}</p></ion-label></ion-col>
          </ion-row>
        </div>




        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-avatar slot="start" v-if="teamB && teamB.teamThumbKey">
                <img :src="'https://storage.googleapis.com/rt-webapp.appspot.com/'+teamB.teamThumbKey" >
              </ion-avatar>

              <ion-avatar  v-if="teamB && !teamB.teamThumbKey" style="text-align: center;" slot="start">
                <svg style="vertical-align: bottom;background-color:transparent;width:36px;height:36px;" width="36" height="36" pointer-events="none">

                  <circle/>
                  <!--                  <circle *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" cx="18" cy="18" r="18" [attr.fill]="utilAvatar.getGravatarColorByLetter( utilAvatar.getTeamSubstrFromScoreboard(scoreboard, 1) )"/>-->

                  <text text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" :fill="getTextFillColor()"
                        style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                    A
                  </text>
                  <!--                  <text *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" fill="white"
                                          style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                                      B
                                    </text>-->
                </svg>
              </ion-avatar>




              <ion-row  style="width:100%;">
                <ion-col size="12"><ion-label><p v-if="teamB">{{ teamB.name }}</p></ion-label></ion-col>
<!--                <ion-col size="3" v-if="scoreboard">{{ scoreboard.scores[1].final }}</ion-col>-->
              </ion-row>
            </ion-item>
          </ion-col>

        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-row>
      <!--      https://www.gravatar.com/avatar/?d=identicon-->
      <ion-col>
        <!--        <ion-avatar slot="start">
                  <img src="https://www.gravatar.com/avatar/?d=identicon">
                </ion-avatar>-->

        <ion-item lines="none">
          <!--          <ion-avatar slot="start">
                      <img src="https://www.gravatar.com/avatar/?d=identicon">
                    </ion-avatar>-->

          <ion-icon name="person" color="medium" size="small" style="vertical-align:middle;" slot="start"  class="ion-no-margin"></ion-icon>

          <ion-row  style="width:100%;">
            <ion-col v-if="scoreboard && scoreboard.username"><ion-label><p>{{ scoreboard.username }}</p></ion-label></ion-col>
            <!--            <ion-col size="3">{{ scoreboard.scores[1].final }}</ion-col>-->
          </ion-row>
        </ion-item>


      </ion-col>

    </ion-row>

    <ion-row v-if="showFooter">
      <ion-col>
        <ion-button fill="clear" size="small">
          <ion-icon slot="start" name="heart-sharp"></ion-icon>
          <div>12 Likes</div>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button fill="clear" size="small">
          <ion-icon name="chatbubble-sharp"></ion-icon>
          <div>4 Comments</div>
        </ion-button>
      </ion-col>
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-note>
          11h ago
        </ion-note>
      </ion-col>
    </ion-row>

    <ion-segment :value="segVal" @ionChange="segmentChanged($event)">
      <ion-segment-button value="all" v-if="hasScoreRecords">
        <ion-label>All</ion-label>
      </ion-segment-button>
      <ion-segment-button value="scoring" v-if="hasScoreRecords">
        <ion-label>Scoring</ion-label>
      </ion-segment-button>
      <ion-segment-button value="box" v-if="hasBox">
        <ion-label>Box</ion-label>
      </ion-segment-button>
    </ion-segment>

    <div v-if="scoreRecords && (showWhich === 'all' || showWhich === 'scoring')">

      <ion-card v-for="score of scoreRecords" :key="score">
        <ion-item lines="none">
          <ion-avatar slot="start" v-if="score && score.url">
            <img :src="score.url">
          </ion-avatar>
          <ion-label class="ion-no-margin ion-no-padding">
            <ion-row>
              <ion-col>
                <span style="padding-left: 8px;">{{ score.teamName }}</span>
              </ion-col>
              <ion-col size="3">
                {{ score.period }} {{ score.time }}
              </ion-col>
            </ion-row>
          </ion-label>
        </ion-item>

        <ion-card-content>
          <ion-row>
            <ion-col>
              <ion-label>
                <span v-if="score.descr1">{{ score.descr1 }}</span>
                <span v-if="score.descr2"><br>{{ score.descr2 }}</span>
              </ion-label>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </div>

    <div v-if="box && showWhich === 'box'">
      <!--TEAM A-->
      <ion-item lines="none">
        <ion-avatar slot="start" v-if="teamA && teamA.teamThumbKey">
          <img :src="'https://storage.googleapis.com/rt-webapp.appspot.com/'+teamA.teamThumbKey">
        </ion-avatar>
        <ion-label class="ion-no-margin ion-no-padding">
          <span style="padding-left: 8px;">{{ teamA.name }}</span>
        </ion-label>
      </ion-item>


      <ion-item-group v-for="(statObj, playerName) in box[teamA.name]" :key="playerName">

        <ion-item lines="none">
          <ion-label> {{ playerName }} <br> {{ getStatsSingleLine(statObj) }}</ion-label>
        </ion-item>

      </ion-item-group>


      <!--TEAM B-->
      <ion-item lines="none">
        <ion-avatar slot="start" v-if="teamB && teamB.teamThumbKey">
          <img :src="'https://storage.googleapis.com/rt-webapp.appspot.com/'+teamB.teamThumbKey">
        </ion-avatar>
        <ion-label class="ion-no-margin ion-no-padding">
          <span style="padding-left: 8px;">{{ teamB.name }}</span>
        </ion-label>
      </ion-item>

      <ion-item-group v-for="(statObj, playerName) in box[teamB.name]" :key="playerName">

        <ion-item lines="none">
          <ion-label> {{ playerName }} <br> {{ getStatsSingleLine(statObj) }}</ion-label>
        </ion-item>

      </ion-item-group>

    </div>


  </ion-card>
</template>

<script lang="ts">

// import { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip } from '@ionic/vue';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonAvatar,
  IonNote,
  IonButton,
  IonIcon,
  IonChip,
  IonSegment,
  IonSegmentButton,
} from '@ionic/vue';
import {computed, defineComponent, ref, watch} from 'vue';
import useUtil from '@/hooks/use-util'
import useScoreRecordUtil from '@/hooks/score-record-util'

export default defineComponent({
  name: 'Gamecenter',
  // components: { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip },
  components: { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonLabel,IonItem,IonAvatar, IonNote, IonButton, IonIcon, IonSegment, IonSegmentButton, IonItemGroup, },
  props: { scoreboard: Object },
  setup(props, context) {
    const { getIsDarkMode, getDisplayDateScoreboardBaseFromIsoStrShort } = useUtil();
    const { getExpandedScoreRecord, getStatsSingleLine, sortScoreRecordsDescByPeriodAndTime } = useScoreRecordUtil();
    // console.log('props.scoreboard', props.scoreboard);
    const showFooter = ref(false);
    const propsScoreboard = ref(props.scoreboard);
    const showWhich = ref('all');
    const teamA = ref({});
    const teamB = ref({});
    const scoreRecords = ref([]);
    const box = ref({});
    const hasScoreRecords = ref(false);
    const hasBox = ref(false);
    const segVal = ref('all');
    if (props.scoreboard && props.scoreboard.cache) {
      if (propsScoreboard.value) {
        teamA.value = propsScoreboard.value.cache.teamA;
        teamB.value = propsScoreboard.value.cache.teamB;
        if (propsScoreboard.value.cache.score_records) {
          // scoreRecords.value = propsScoreboard.value.cache.score_records;
        }
      }
    }
    // console.log('GamecenterVue.vue A', props.scoreboard);

    function getTextFillColor() {
      if (getIsDarkMode()) {
        return 'white';
      } else {
        return 'black';
      }
    }

    function clearDefaults() {
      teamA.value = {};
      teamB.value = {};
      scoreRecords.value = [];
    }

    function segmentChanged(ev: CustomEvent) {
      // console.log('Segment changed', ev);
      showWhich.value = ev.detail.value;
      console.log('Segment showWhich.value', showWhich.value);
    }

    watch(() => props.scoreboard, () => {
      clearDefaults();
      // console.log('GamecenterVue.vue B-1', props.scoreboard);
      if (props.scoreboard) {
        // console.log('GamecenterVue.vue B-2', props.scoreboard);
        teamA.value = props.scoreboard.cache.teamA;
        teamB.value = props.scoreboard.cache.teamB;
        if (props.scoreboard.cache.score_records) {
          // console.log('GamecenterVue.vue B-3', props.scoreboard);
          scoreRecords.value = [];
          let tmp: any = [];
          // console.log('GamecenterVue.vue B-4', props.scoreboard.cache.score_records);
          for (const srId in props.scoreboard.cache.score_records) {
            const sr = getExpandedScoreRecord(props.scoreboard.cache.score_records[srId], props.scoreboard, teamA.value, teamB.value );
            tmp.push(sr);
            // console.log('GamecenterVue.vue B-5', sr);
          }

          tmp = sortScoreRecordsDescByPeriodAndTime(tmp);
          scoreRecords.value = tmp;
          if (tmp.length > 0) {
            hasScoreRecords.value = true;
          }
          // console.log('GamecenterVue.vue B-6 scoreRecords.value', scoreRecords.value);
        }

        if (props.scoreboard.cache.prebuilt_box_expanded) {
          box.value = props.scoreboard.cache.prebuilt_box_expanded;
          hasBox.value = true;
        } else if (props.scoreboard.cache.prebuilt_box) {
          box.value = props.scoreboard.cache.prebuilt_box;
          hasBox.value = true;
        }
        // console.log('GamecenterVue.vue B', props.scoreboard);
      }

      if (!hasScoreRecords.value && hasBox.value) {
        console.log('changing segment tobox')
        showWhich.value = 'box';
        segVal.value = 'box';
      }
    });


    return {
      getDisplayDateScoreboardBaseFromIsoStrShort,
      showFooter,
      teamA,
      teamB,
      scoreRecords,
      box,
      getIsDarkMode,
      getTextFillColor,
      segmentChanged,
      getStatsSingleLine,
      showWhich,
      hasScoreRecords,
      hasBox,
      segVal,
    }
  }
});
</script>
<style>
ion-card, .card-ios, .card-md {
  border-bottom: 1px solid rgb(243,243,243) !important;
  border: 1px solid rgb(243,243,243) !important;
  box-shadow: none !important;
}
</style>
