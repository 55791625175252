

// import { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip } from '@ionic/vue';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonLabel,IonItem,IonAvatar, IonNote, IonButton, IonIcon, IonChip, IonBadge } from '@ionic/vue';
import { playOutline, cubeOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
import useUtil from '@/hooks/use-util'
import {useRouter} from "vue-router";
import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";

export default defineComponent({
  name: 'Scoreboard',
  // components: { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip },
  components: { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonLabel,IonItem,IonAvatar, IonNote, IonButton, IonIcon, IonChip, IonBadge, /*IonLabel, IonItem */},
  props: { scoreboard: Object, scoreboardId: String },
  setup(props, context) {
    const {getIsDarkMode, getDisplayDateScoreboardBaseFromIsoStrShort} = useUtil();
    const router = useRouter();
    // console.log('props.scoreboard', props.scoreboard);
    const showFooter = ref(false);
    const propsScoreboard = ref(props.scoreboard);
    const teamA = ref({});
    const teamB = ref({});
    const showStatIcon = ref(false);
    const showBoxIcon = ref(false);
    if (props.scoreboard && props.scoreboard.cache) {
      if (propsScoreboard.value) {
        // console.log('propsScoreboard.value', propsScoreboard.value);
        teamA.value = propsScoreboard.value.cache.teamA;
        teamB.value = propsScoreboard.value.cache.teamB;
        if (propsScoreboard.value.cache) {
          /*if (propsScoreboard.value.cache.prebuilt_box || propsScoreboard.value.cache.prebuilt_box_expanded || propsScoreboard.value.cache.score_records) {
            showStatIcon.value = true;
          }*/
          if (propsScoreboard.value.cache.score_records) {
            showStatIcon.value = true;
          }
          if (propsScoreboard.value.cache.prebuilt_box || propsScoreboard.value.cache.prebuilt_box_expanded) {
            showBoxIcon.value = true;
          }

        }
      }
    }

    watch(() => props.scoreboardId, (first, second) => {
      console.log(
          "Watch props.scoreboard function called with args:",
          first,
          second
      );
    });


    const routeToScoreboard = async (scoreboardId: string) => {
      console.log('routeToScoreboard');
      // await router.replace({ path: '/scores', query: { scoreboardId: scoreboardId } } );
      await router.push({ path: '/' + CONFIG_ROUTE_SCORES, query: { scoreboardId: scoreboardId } } );
      context.emit('scoreboard-emit-from-scoreboard', propsScoreboard.value);
    }

    // context.emit('scoreboard-emit-from-scoreboard', propsScoreboard.value);
    return {
      getDisplayDateScoreboardBaseFromIsoStrShort,
      showFooter,
      teamA,
      teamB,
      routeToScoreboard,
      getIsDarkMode,
      showStatIcon,
      showBoxIcon,
      playOutline,
      cubeOutline,
    }
  }
});
