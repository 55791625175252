<template>
  <ion-card lines="none">

    <ion-card-header>
      <ion-row>
        <ion-col>
          <ion-card-subtitle>{{ scoreboard.period }}</ion-card-subtitle>
          <ion-card-subtitle>{{ scoreboard.event.gender }} {{ scoreboard.event.sport }}</ion-card-subtitle>
          <ion-card-subtitle>{{ getDisplayDateScoreboardBaseFromIsoStrShort(scoreboard.event.datetime) }}</ion-card-subtitle>
        </ion-col>

        <ion-col size="3" style="border: 0px solid red;" class="ion-align-items-end ion-text-end">
          <ion-chip color="primary" @click="routeToScoreboard(scoreboard.id)">
            <ion-label>Open</ion-label>
          </ion-chip>
        </ion-col>

      </ion-row>
    </ion-card-header>

    <ion-card-content>
      <ion-row>
        <div v-if="scoreboard.event.tags">
          <ion-badge style="margin: 3px;" v-for="(value, name) in scoreboard.event.tags" :key="name">{{ name }}</ion-badge>
        </div>
        <div v-else>
          <ion-badge style="margin: 3px; opacity: 0;" >1</ion-badge>
        </div>
      </ion-row>


      <ion-grid>
        <!-- TEAM A ROW-->
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-avatar slot="start" v-if="teamA.teamThumbKey">
                <img :src="'https://storage.googleapis.com/rt-webapp.appspot.com/'+teamA.teamThumbKey">
              </ion-avatar>

              <ion-avatar  v-if="!teamA.teamThumbKey" style="text-align: center;" slot="start">
                <svg style="vertical-align: bottom;background-color:transparent;width:36px;height:36px;" width="36" height="36" pointer-events="none">

                  <circle/>
                  <!--                  <circle *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" cx="18" cy="18" r="18" [attr.fill]="utilAvatar.getGravatarColorByLetter( utilAvatar.getTeamSubstrFromScoreboard(scoreboard, 1) )"/>-->

                  <text text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" fill="white"
                        style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                    A
                  </text>
                  <!--                  <text *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" fill="white"
                                          style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                                      B
                                    </text>-->
                </svg>
              </ion-avatar>

              <ion-row  style="width:100%;">
                <ion-col size="9"><ion-label><p>{{ teamA.name }}</p></ion-label></ion-col>
                <ion-col size="3">{{ scoreboard.scores[0].final }}</ion-col>
              </ion-row>
            </ion-item>
          </ion-col>
        </ion-row>





        <!-- TEAM B ROW-->
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-avatar slot="start" v-if="teamB.teamThumbKey">
                <img :src="'https://storage.googleapis.com/rt-webapp.appspot.com/'+teamB.teamThumbKey">
              </ion-avatar>

              <ion-avatar  v-if="!teamB.teamThumbKey" style="text-align: center;" slot="start">
                <svg style="vertical-align: bottom;background-color:transparent;width:36px;height:36px;" width="36" height="36" pointer-events="none">

                  <circle/>
<!--                  <circle *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" cx="18" cy="18" r="18" [attr.fill]="utilAvatar.getGravatarColorByLetter( utilAvatar.getTeamSubstrFromScoreboard(scoreboard, 1) )"/>-->

                  <text text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" fill="white"
                        style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                    A
                  </text>
<!--                  <text *ngIf="scoreboard && scoreboard.event && scoreboard.event.teamB && teamWhich === 'b'" text-anchor="middle" x="50%" y="50%" dy="0.35em" pointer-events="auto" fill="white"
                        style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif; font-weight:400;font-size:18px;">
                    B
                  </text>-->
                </svg>
              </ion-avatar>

              <ion-row  style="width:100%;">
                <ion-col size="9"><ion-label><p>{{ teamB.name }}</p></ion-label></ion-col>
                <ion-col size="3">{{ scoreboard.scores[1].final }}</ion-col>
              </ion-row>
            </ion-item>
          </ion-col>

        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-icon name="person" color="medium" size="small" style="vertical-align:middle;" slot="start"  class="ion-no-margin"></ion-icon>
          <ion-row  style="width:100%;">
            <ion-col v-if="scoreboard && scoreboard.username"><ion-label><p>{{ scoreboard.username }}</p></ion-label></ion-col>
            <ion-col size="1" v-if="showStatIcon"><ion-icon :src="playOutline"></ion-icon></ion-col>
            <ion-col size="1" v-if="showBoxIcon"><ion-icon :src="cubeOutline"></ion-icon></ion-col>
          </ion-row>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row v-if="showFooter">
      <ion-col>
        <ion-button fill="clear" size="small">
          <ion-icon slot="start" name="heart-sharp"></ion-icon>
          <div>12 Likes</div>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button fill="clear" size="small">
          <ion-icon name="chatbubble-sharp"></ion-icon>
          <div>4 Comments</div>
        </ion-button>
      </ion-col>
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-note>
          11h ago
        </ion-note>
      </ion-col>
    </ion-row>


  </ion-card>
</template>

<script lang="ts">

// import { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip } from '@ionic/vue';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonLabel,IonItem,IonAvatar, IonNote, IonButton, IonIcon, IonChip, IonBadge } from '@ionic/vue';
import { playOutline, cubeOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
import useUtil from '@/hooks/use-util'
import {useRouter} from "vue-router";
import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";

export default defineComponent({
  name: 'Scoreboard',
  // components: { IonCol, IonRow, IonItem, IonLabel, IonCard, IonGrid, IonChip },
  components: { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonLabel,IonItem,IonAvatar, IonNote, IonButton, IonIcon, IonChip, IonBadge, /*IonLabel, IonItem */},
  props: { scoreboard: Object, scoreboardId: String },
  setup(props, context) {
    const {getIsDarkMode, getDisplayDateScoreboardBaseFromIsoStrShort} = useUtil();
    const router = useRouter();
    // console.log('props.scoreboard', props.scoreboard);
    const showFooter = ref(false);
    const propsScoreboard = ref(props.scoreboard);
    const teamA = ref({});
    const teamB = ref({});
    const showStatIcon = ref(false);
    const showBoxIcon = ref(false);
    if (props.scoreboard && props.scoreboard.cache) {
      if (propsScoreboard.value) {
        // console.log('propsScoreboard.value', propsScoreboard.value);
        teamA.value = propsScoreboard.value.cache.teamA;
        teamB.value = propsScoreboard.value.cache.teamB;
        if (propsScoreboard.value.cache) {
          /*if (propsScoreboard.value.cache.prebuilt_box || propsScoreboard.value.cache.prebuilt_box_expanded || propsScoreboard.value.cache.score_records) {
            showStatIcon.value = true;
          }*/
          if (propsScoreboard.value.cache.score_records) {
            showStatIcon.value = true;
          }
          if (propsScoreboard.value.cache.prebuilt_box || propsScoreboard.value.cache.prebuilt_box_expanded) {
            showBoxIcon.value = true;
          }

        }
      }
    }

    watch(() => props.scoreboardId, (first, second) => {
      console.log(
          "Watch props.scoreboard function called with args:",
          first,
          second
      );
    });


    const routeToScoreboard = async (scoreboardId: string) => {
      console.log('routeToScoreboard');
      // await router.replace({ path: '/scores', query: { scoreboardId: scoreboardId } } );
      await router.push({ path: '/' + CONFIG_ROUTE_SCORES, query: { scoreboardId: scoreboardId } } );
      context.emit('scoreboard-emit-from-scoreboard', propsScoreboard.value);
    }

    // context.emit('scoreboard-emit-from-scoreboard', propsScoreboard.value);
    return {
      getDisplayDateScoreboardBaseFromIsoStrShort,
      showFooter,
      teamA,
      teamB,
      routeToScoreboard,
      getIsDarkMode,
      showStatIcon,
      showBoxIcon,
      playOutline,
      cubeOutline,
    }
  }
});
</script>
<style>
  ion-card, .card-ios, .card-md {
    border-bottom: 1px solid rgb(243,243,243) !important;
    border: 1px solid rgb(243,243,243) !important;
    box-shadow: none !important;
  }
</style>
