<template>
  <div>
    <ion-header class="ion-no-border ion-padding">
      <ion-row class="ion-padding">
        <ion-col style="display: flex; justify-content: center" >
          <ion-avatar>
            <img v-if="user && user.photoURL" :src="user.photoURL" alt="">
          </ion-avatar>
        </ion-col>
      </ion-row>
      <ion-row >
        <ion-col style="display: flex; justify-content: center" size="12">
          <ion-item v-if="user" lines="none" class="ion-text-center">
            {{ user.displayName }}<br>{{ user.email }}
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-row >
        <ion-col style="display: flex; justify-content: center" size="12">
          <ion-button color="medium" fill="outline" @click="doLogout">Sign Out Of Your Account</ion-button>
        </ion-col>
      </ion-row>

    </ion-content>
  </div>
</template>

<script>
import {
  IonContent,
  IonRow,
  IonCol,
  IonHeader,
  IonButton,
  IonAvatar,
  IonItem,
  modalController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import useFirebaseAuth from "@/hooks/firebase-auth.ts";
import { useRouter} from "vue-router";

export default defineComponent({
  name: 'UserPopup',
  props: {
    title: { type: String, default: 'Super Modal' },
  },
  components: { IonContent, IonRow, IonCol, IonHeader, IonButton, IonAvatar, IonItem },
  setup() {
    const { logout, user } = useFirebaseAuth();
    const router = useRouter();
    const doLogout = async () => {
      await logout();
      await modalController.dismiss();
      await router.replace({ path: "/login" });
    };

    return { user, doLogout }
  },

});
</script>

<style>

/*.loginModalCss .modal-wrapper {
  width: 450px;
  height: 375px;
}*/

.userModalCss .modal-wrapper {
  width: 450px;
  height: 325px;
}
</style>
